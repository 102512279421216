<template>
  <div class="content activity-data">
    <fm-form :inline="3" label-alone label-align="left" style="position: relative;">
      <fm-form-item label="活动类型">
        <fm-select absolute filterable :clearable="false" v-model="activityData.activityTypeId" :disabled="true">
          <fm-option v-for="item in activityTypeList" :key="item.id" :value="item.id" :label="item.name"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="主办人员">
        <fm-select absolute filterable :clearable="!readOnly" v-model="activityData.hostUserId" :disabled="readOnly">
          <fm-option v-for="item in userList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="归属组织">
        <fm-select absolute filterable :clearable="!readOnly" v-model="activityData.hostOrgId" :disabled="readOnly">
          <fm-option v-for="item in $store.getters.orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="参与总数">
        <fm-input-new placeholder="参与总数" v-model="activityData.participateTotal" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="开始时间">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          v-model="activityData.startDate"
          format="Y-M-D"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="结束时间">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          v-model="activityData.endDate"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="上传时间">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          v-model="activityData.uploadTime"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="受益人数">
        <fm-input-new placeholder="受益人数" v-model="activityData.receiveTotal" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="活动标题" style="width: 100%">
        <fm-input-new placeholder="活动标题" v-model="activityData.name" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item :label="'活动地点' + (activityData.lng && activityData.lat ? ('(关联坐标：' + activityData.lng + ',' + activityData.lat) : '暂未关联坐标')" style="width: 100%">
        <fm-input-new placeholder="活动地点" v-model="activityData.address" :disabled="readOnly">
          <span @click="status.map = true" style="cursor: pointer;" slot="append">{{activityData.lng && activityData.lat ? '查看或修改坐标' : '地图标点'}}</span>
        </fm-input-new>
      </fm-form-item>
      <fm-form-item label="活动简介" style="width: 100%;">
        <fm-input-new style="height: 10rem;" type="textarea" placeholder="活动简介" v-model="activityData.content" :disabled="readOnly"/>
      </fm-form-item>
      <cover-img :readOnly="readOnly" :activityData="activityData" @fileChange="fileChange"></cover-img>
      <fm-form-item label="活动材料" style="width: 66%;">
        <file-manage
          preview
          @uploadIng="uploadIng"
          :file-data-ids="fileIds"
          @addFile="addFile"
          @delFile="delFile"
          :funs="{get: true, upload: !readOnly, del: !readOnly}">
        </file-manage>
      </fm-form-item>
      <fm-form-item label="参与人">
        <user-choose v-if="!readOnly" :userList="userList" v-model="activityData.participateUserIdList" />
        <template v-else>
          <span style="padding-right: 20px;" v-for="item in activityData.participateUserIdList" :key="item">
            {{userList.find(v => v.key === item).label}}
          </span>
        </template>
      </fm-form-item>
      <fm-form-item label="编辑人">
        <user-choose v-if="!readOnly" :userList="userList" v-model="activityData.editUserIdList" />
        <template v-else>
          <div v-for="item in activityData.editUserIdList" :key="item">
            {{userList.find(v => v.key === item).label}}
          </div>
        </template>
      </fm-form-item>
      <fm-form-item label="其他可查看党组织">
        <fm-select absolute filterable multiple :clearable="!readOnly" v-model="activityData.getOrgIdList" :disabled="readOnly">
          <fm-option v-for="item in $store.getters.orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item :class="{'form-item-f': item.type === 'file'}" :label="item.key" v-for="(item, index) in formParm" :key="index">
        <fm-input-new :placeholder="item.key" v-if="item.type === 'text' || item.type === 'number'" v-model="otherInfo[item.key]" :disabled="readOnly"/>
        <fm-input-new :placeholder="item.key" type="textarea" v-else-if="item.type === 'longText'" v-model="otherInfo[item.key]" :disabled="readOnly"/>
        <fm-date-picker
          v-else-if="item.type === 'date'"
          absolute
          :readonly="readOnly"
          v-model="otherInfo[item.key]"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
        ></fm-date-picker>
        <fm-date-picker
          v-else-if="item.type === 'dataTime'"
          absolute
          :readonly="readOnly"
          v-model="otherInfo[item.key]"
          :clearable="!readOnly"
          placeholder="请选择"
          type="datetime"
        ></fm-date-picker>
        <file-manage
          v-else-if="item.type === 'file'"
          preview
          @uploadIng="uploadIng"
          :file-data-ids="otherInfo[item.key] || []"
          @addFile="otherAddFile(item.key, $event)"
          @delFile="otherDelFile(item.key, $event)"
          :funs="{get: true, upload: !readOnly, del: !readOnly}">
        </file-manage>
      </fm-form-item>
    </fm-form>
    <fm-modal :mask-closable="false" width="800px" v-model="status.map" title="坐标搜索">
      <MapSearch v-if="status.map" @choose="onMapChoose" style="width: 100%;height: calc(80vh - 100px);" :search-key="activityData.address" :marked="activityData.lng && activityData.lat ? [
        {
          point: {
            lng: activityData.lng,
            lat: activityData.lat
          },
          title: activityData.name,
          address: activityData.address
        }
      ] : []" />
    </fm-modal>
  </div>
</template>

<script>
import FileManage from '@/components/base/FileManage'
import MapSearch from '@/components/map/search'
import UserChoose from '../../../components/user'
import CoverImg from './coverImg'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    FileManage,
    CoverImg,
    UserChoose,
    MapSearch
  },
  props: {
    sourceData: {
      type: Object
    },
    activityTypeList: {
      type: Array
    },
    userList: {
      type: Array
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activityData: {
        startData: null,
        endData: null
      },
      fileIds: [],
      formParm: [],
      otherInfo: {},
      loading: false,
      status: {
        map: false
      }
    }
  },
  watch: {
    sourceData: {
      handler () {
        let data = JSON.parse(JSON.stringify(this.sourceData))
        this.otherInfo = data.otherInfo ? JSON.parse(data.otherInfo) : {}
        this.fileIds = data.fileIds ? data.fileIds.split(',').map(v => Number(v)) : []
        this.fileIds = data.fileIds ? data.fileIds.split(',').map(v => Number(v)) : []
        this.activityData = data
        this.activityData.participateUserIdList = data.participateUserIds ? data.participateUserIds.split(',').map(v => Number(v)) : []
        this.activityData.editUserIdList = data.editUserIds ? data.editUserIds.split(',').map(v => Number(v)) : []
        this.activityData.getOrgIdList = data.getOrgIds ? data.getOrgIds.split(',').map(v => Number(v)) : []
        this.activityData.lng = data.lng ? data.lng : null
        this.activityData.lat = data.lat ? data.lat : null
        this.formParm = this.activityData.activityType && this.activityData.activityType.config ? JSON.parse(this.activityData.activityType.config) : []
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onMapChoose ({ point, address }) {
      this.activityData.lng = point.lng
      this.activityData.lat = point.lat
      if (!this.activityData.address && address) {
        this.activityData.address = address
      }
      this.status.map = false
    },
    uploadIng (uploadIng) {
      this.loading = uploadIng
    },
    addFile (data) {
      this.fileIds.push(data.id)
    },
    delFile (data) {
      this.fileIds = this.fileIds.filter(v => Number(v) !== data.id)
    },
    fileChange (data) {
      this.activityData.coverFileId = data.id
    },
    otherAddFile (key, f) {
      if (!this.otherInfo[key]) {
        this.otherInfo[key] = []
      }
      this.otherInfo[key].push(f.id)
    },
    otherDelFile (key, f) {
      if (this.otherInfo[key]) {
        this.otherInfo[key] = this.otherInfo[key].filter(v => v !== f.id)
      }
    },
    headFileChange (fileData) {
      this.$set(this.activityData, 'headFileId', fileData.id)
    },
    dealNumber (n) {
      if (typeof n === 'string') {
        n = n.replaceAll(' ', '')
      }
      if (n === 0) {
        return n
      }
      if (!n) {
        return null
      }
      if (isNaN(n)) {
        return 'error'
      }
      return n
    },
    getFormData () {
      let data = Object.assign({}, this.activityData)
      if (!data.startDate) {
        data.startDate = null
      } else if (data.startDate instanceof Date) {
        data.startDate = dateOperating.computeDay({days: 0, date: data.startDate, format: 'yy-mm-dd hh:mm:ss'})
      }
      if (!data.endDate) {
        data.endDate = null
      } else if (data.endDate instanceof Date) {
        data.endDate = dateOperating.computeDay({days: 0, date: data.endDate, format: 'yy-mm-dd hh:mm:ss'})
      }
      if (!data.uploadTime) {
        data.uploadTime = null
      } else if (data.uploadTime instanceof Date) {
        data.uploadTime = dateOperating.computeDay({days: 0, date: data.uploadTime, format: 'yy-mm-dd hh:mm:ss'})
      }
      let participateTotal = this.dealNumber(data.participateTotal)
      if (participateTotal === 'error') {
        this.$notify({
          title: '系统提示',
          message: '参与总数请输入数值',
          type: 'info'
        })
        return
      }
      data.fileIds = this.fileIds.length > 0 ? this.fileIds.join(',') : null
      data.participateUserIds = data.participateUserIdList.length > 0 ? data.participateUserIdList.join(',') : null
      data.editUserIds = data.editUserIdList.length > 0 ? data.editUserIdList.join(',') : null
      data.getOrgIds = data.getOrgIdList.length > 0 ? data.getOrgIdList.join(',') : null
      data.participateTotal = participateTotal
      let receiveTotal = this.dealNumber(data.receiveTotal)
      if (receiveTotal === 'error') {
        this.$notify({
          title: '系统提示',
          message: '受益人数请输入数值',
          type: 'info'
        })
        return
      }
      data.receiveTotal = receiveTotal

      let otherInfo = Object.assign({}, this.otherInfo)
      let i = 0
      while (i < this.formParm.length) {
        let v = this.formParm[i]
        if (v.type === 'number') {
          let d = this.dealNumber(otherInfo[v.key])
          if (d === 'error') {
            this.$notify({
              title: '系统提示',
              message: v.key + '请输入数值',
              type: 'info'
            })
            return
          }
          otherInfo[v.key] = d
        } else if (v.type === 'date' || v.type === 'dataTime') {
          if (!otherInfo[v.key]) {
            otherInfo[v.key] = null
          } else if (otherInfo[v.key] instanceof Date) {
            otherInfo[v.key] = dateOperating.computeDay({days: 0, date: otherInfo[v.key], format: 'yy-mm-dd hh:mm:ss'})
          }
        }
        i += 1
      }
      data.otherInfo = JSON.stringify(otherInfo)
      console.log(data)
      return data
    }
  }
}
</script>

<style scoped lang="less">
.activity-data {
  .fm-form-inline {
    padding: 0 0 0 16px !important;
  }
  .form-item-f {
    width: 100% !important;
  }
}
</style>
